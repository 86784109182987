let video_controls = document.querySelectorAll(".js-videoControls");
video_controls.forEach((controls) => {
    let media_el = controls.closest(".js-media"),
        video_el = media_el.querySelector("video"),
        btn_play_pause = media_el.querySelectorAll(".js-playPause"),
        btn_fullscreen = media_el.querySelector(".js-fullscreen"),
        timeline = media_el.querySelector(".js-timeline"),
        timeline_inner = media_el.querySelector(".js-inner");

    // update the progress bar
    video_el.addEventListener("timeupdate", () => {
        let curr = Math.floor((video_el.currentTime / video_el.duration) * 100);
        if (video_el.ended) {
            media_el.classList.remove("is-playing");
        }
        timeline_inner.style.width = `${curr}%`;
    });

    // update the progress bar
    timeline.addEventListener("mousedown", (e) => {
        const target = e.target;
        const rect = target.getBoundingClientRect();
        const clickedX = e.clientX - rect.left;
        const relX = clickedX / rect.width;

        const video_length = video_el.duration;

        const video_skip_to = video_length * relX;

        video_el.currentTime = video_skip_to;
    });

    video_el.addEventListener("play", () => {
        media_el.classList.add("is-playing");
    });

    video_el.addEventListener("pause", () => {
        media_el.classList.remove("is-playing");
    });

    video_el.addEventListener("click", () => {
        if (video_el.paused) {
            video_el.play();
        } else {
            video_el.pause();
        }
    });

    btn_play_pause.forEach((button) => {
        button.addEventListener("click", () => {
            if (video_el.paused) {
                video_el.play();
            } else {
                video_el.pause();
            }
        });
    });

    btn_fullscreen.addEventListener("click", () => {
        if (video_el.requestFullscreen) {
            video_el.requestFullscreen();
        } else if (video_el.webkitEnterFullScreen) {
            video_el.webkitEnterFullScreen();
        } else if (video_el.mozRequestFullScreen) {
            video_el.mozRequestFullScreen();
        } else if (video_el.webkitRequestFullscreen) {
            video_el.webkitRequestFullscreen();
        } else if (video_el.msRequestFullscreen) {
            video_el.msRequestFullscreen();
        }
    });
});

let sound_controls = document.querySelectorAll(".js-soundControl");
sound_controls.forEach((control) => {
    let media_el = control.closest(".js-media"),
        video_el = media_el.querySelector("video");

    control.addEventListener("click", () => {
        if (video_el.muted === true) {
            video_el.muted = false;
            control.classList.remove("is-unmute");
            control.classList.add("is-mute");
        } else {
            video_el.muted = true;
            control.classList.remove("is-mute");
            control.classList.add("is-unmute");
        }
    });
});

window.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
        document.body.classList.add("is-fullscreen");
    } else {
        document.body.classList.remove("is-fullscreen");
    }
});
