function is_touch_device() {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
}

var logoAnimating = false,
    logoAnimation,
    marqueAnimating = false,
    marqueAnimation,
    // logoVariants = [
    //     '<a href="/">Business<span class="Main-titleDivider">/</span>Club<span class="Main-titleDivider">/</span>Ro<span class="u-kern--ya">ya</span>le</a>',
    //     '<a href="/">Business<span class="Main-titleDivider">/</span><span class="Main-titleIcon">C</span><span class="Main-titleDivider">/</span><span class="Main-titleIcon">R</span></a>',
    //     '<a href="/">B<span class="Main-titleDivider">/</span>Club<span class="Main-titleDivider">/</span><span class="Main-titleIcon">R</span></a>',
    //     '<a href="/">B<span class="Main-titleDivider">/</span><span class="Main-titleIcon">C</span><span class="Main-titleDivider">/</span>Ro<span class="u-kern--ya">ya</span>le</a>',
    //     '<a href="/">B<span class="Main-titleDivider">/</span><span class="Main-titleIcon">C</span><span class="Main-titleDivider">/</span><span class="Main-titleIcon">R</span></a>'
    // ],
    logoVariants = [
        '<a href="/">Business<span class="Main-titleDivider">/</span>Club</a>',
        '<a href="/">Business<span class="Main-titleDivider">/</span><span class="Main-titleIcon">C</span></a>',
        '<a href="/">B<span class="Main-titleDivider">/</span>Club</a>',
        '<a href="/">B<span class="Main-titleDivider">/</span><span class="Main-titleIcon">C</span></a>'
    ],
    logoCurrent = 1,
    marqueVariants = ['Marque-icon--carl','Marque-icon--smiley','Marque-icon--cherry','Marque-icon--chart','Marque-icon--chip','Marque-icon--bitcoin','Marque-icon--shutter','Marque-icon--eye'],
    marqueVariantsFinal = ['Marque-icon--outline_c','Marque-icon--outline_r'];

function Shuffle(o) {
	for(var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
	return o;
};

function startAnimatingLogo() {
    if ( !logoAnimating ) {
        logoAnimating = true;
        logoAnimation = setInterval(function(){
            var random = Math.floor(Math.random() * 4);
            if ( random == logoCurrent ) {
                if ( random == ( logoVariants.length - 1 ) ) {
                    random = 0;
                } else {
                    random++;
                }
            }
            logoCurrent = random;
            $('.js-logo').html(logoVariants[random]);
        }, 200);
    }
}

function stopAnimatingLogo() {
    if ( logoAnimating ) {
        logoAnimating = false;
        $('.js-logo').html(logoVariants[0]);
        clearInterval(logoAnimation);
    }
}

function startAnimatingMarque() {
    if ( !marqueAnimating ) {
        marqueAnimating = true;
        var icons = marqueVariants;
        Shuffle(icons);
        $('.js-iconTop').addClass(icons[0]);
        $('.js-iconBottom').addClass(icons[1]);
        marqueAnimation = setInterval(function(){
            $('.js-iconTop, .js-iconBottom').removeClass (function (index, className) {
                return (className.match (/(^|\s)Marque-icon--\S+/g) || []).join(' ');
            });
            var icons = marqueVariants;
            Shuffle(icons);
            $('.js-iconTop').addClass(icons[0]);
            $('.js-iconBottom').addClass(icons[1]);
        }, 400);
    }
}

function stopAnimatingMarque() {
    if ( marqueAnimating ) {
        marqueAnimating = false;
        $('.js-iconTop, .js-iconBottom').removeClass (function (index, className) {
            return (className.match (/(^|\s)Marque-icon--\S+/g) || []).join(' ');
        });
        $('.js-iconTop').addClass(marqueVariantsFinal[0]);
        $('.js-iconBottom').addClass(marqueVariantsFinal[1]);
        setTimeout(function(){
            $('.js-iconTop, .js-iconBottom').removeClass (function (index, className) {
                return (className.match (/(^|\s)Marque-icon--\S+/g) || []).join(' ');
            });
        }, 1000);
        clearInterval(marqueAnimation);
    }
}

var favIconIndex = 1;
setTimeout(function(){
    setInterval(function(){
        favIconIndex = favIconIndex === 3 ? 1 : favIconIndex + 1;
    }, 1000);
}, 2000);

$(window).on('load',function(){
    $('body').addClass('is-loaded');
    if ( !$('body').hasClass('is-state--play') && !is_touch_device() ) {
        startAnimatingLogo();
        setTimeout(function(){
            stopAnimatingLogo();
        }, 4000);
    }
});

$(window).on('beforeunload',function(e){
    $('body').removeClass('is-loaded');
});

function playVideo(video, title) {
    if ( video ) {
        $(".js-player").append('<video class="Player-video js-video" preload><source src="'+video+'" type="video/mp4"></source></video>' );
        $(".js-player .js-title").html(title);
        if ( is_touch_device() ) {
            $('.js-video').on('touchend',function(){
                this.paused ? this.play() : this.pause();
                $('body').addClass('is-state--playing');
            });
        } else {
            $('.js-video').on('click',function(){
                this.paused ? this.play() : this.pause();
                $('body').addClass('is-state--playing');
            });
        }
    }
}

if ( is_touch_device() ) {

    $('.js-nav a').on('touchend touchcancel', function(e){
        e.stopPropagation();
        var id = $(this).attr('href').substr(1);
        if ( $("#"+id) ) {
            $('html, body').animate({
                scrollTop: $("#"+id).offset().top
            }, 1000);
            return false;
        }
    });

    $('.js-workTitle').on('click', function(){
        $('body').addClass('is-state--play');
        $('.js-gridLoop').each(function(){
            this.pause();
        });
        var video = $(this).attr('data-video'),
            title = $(this).attr('data-title');
        playVideo(video, title);
    });

    $('.js-reel').on('click', function(){
        $('body').addClass('is-state--play');
        $('.js-gridLoop').each(function(){
            this.pause();
        });
        var video = $(this).attr('data-video'),
            title = $(this).attr('data-title');
        playVideo(video, title);
    });

    $('.js-gridItem').on('click', function(){
        $('body').addClass('is-state--play');
        $('.js-gridLoop').each(function(){
            this.pause();
        });
        var video = $(this).attr('data-video'),
            title = $(this).attr('data-title');
        playVideo(video, title);
    });

    $('.js-back').on('touchend touchcancel', function(){
        $('.js-video').remove();
        $('body').removeClass('is-state--play');
        $('body').removeClass('is-state--playing');
        $('.js-gridLoop').each(function(){
            this.play();
        });
    });

    $('.js-unmute').on('touchend touchcancel', function(){
        $('body').toggleClass('is-state--sound');
        var video = $(".js-player").find('video');
        if ( video.prop('muted') ) {
            $("video").prop('muted', false);
        } else {
            $("video").prop('muted', true);
        }
    });

} else {

    $('.js-nav').on('mouseenter',function(){
        if ( !$('body').hasClass('is-state--play') ) {
            startAnimatingLogo();
        }
    });

    $('.js-nav').on('mouseleave',function(){
        stopAnimatingLogo();
    });

    $('.js-nav a').on('click', function(e){
        e.stopPropagation();
        var id = $(this).attr('href').substr(1);
        if ( $("#"+id) ) {
            $('html, body').animate({
                scrollTop: $("#"+id).offset().top
            }, 1000);
            return false;
        }
    });

    $('.js-workTitle').on('mouseenter',function(){
        var loop = $(this).find('.js-workLoop').get(0);
        loop.play();
    });

    $('.js-workTitle').on('mouseleave',function(){
        var loop = $(this).find('.js-workLoop').get(0);
        loop.pause();
    });

    $('.js-workTitle').on('mousemove',function(e){
        var winWidth = $(window).width(),
            maxWidth = $('.js-workTitle').width(),
            x = (event.pageX - (winWidth - maxWidth)/2),
            loopWidth = $(this).find('.js-workLoop').width();
        if ( x > loopWidth ) {
            $(this).find('.js-workLoop').css({
                'left': (x-loopWidth)
            });
        } else {
            $(this).find('.js-workLoop').css({
                'left': 0
            });
        }
    });

    $('.js-workTitle').on('click', function(){
        var loop = $(this).find('.js-workLoop').get(0);
        loop.pause();
        $('body').addClass('is-state--play');
        var video = $(this).attr('data-video'),
            title = $(this).attr('data-title');
        playVideo(video, title);
    });

    $('.js-reel').on('click', function(){
        $('body').addClass('is-state--play');
        var video = $(this).attr('data-video'),
            title = $(this).attr('data-title');
        playVideo(video, title);
    });

    $('.js-back').on('click', function(){
        $('.js-video').remove();
        $('body').removeClass('is-state--play');
        $('body').removeClass('is-state--playing');
    });

    $('.js-gridItem').on('click', function(){
        $('body').addClass('is-state--play');
        var video = $(this).attr('data-video'),
            title = $(this).attr('data-title');
        playVideo(video, title);
    });

    $('.js-unmute').on('click', function(){
        $('body').toggleClass('is-state--sound');
        var video = $(".js-player").find('video');
        if ( video.prop('muted') ) {
            $("video").prop('muted', false);
        } else {
            $("video").prop('muted', true);
        }
    });

}
