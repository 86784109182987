const $images = document.querySelectorAll('.js-galleryImage');

if ( $images ) {

    $images.forEach((image) => {
        image.addEventListener('click', () => {
            image.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
        });
    });

}